<template>
    <i class="bi bi-sort-up" v-if="attrs.currentFieldName === attrs.fieldName && attrs.order === 'asc'"></i>
    <i class="bi bi-sort-down" v-if="attrs.currentFieldName === attrs.fieldName && attrs.order === 'desc'"></i>
    <i class="bi bi-chevron-expand" v-if="attrs.currentFieldName !== attrs.fieldName || attrs.order === null"></i>
</template>

<script setup>
import {useAttrs} from "vue";

const attrs = useAttrs({
    fieldName: String,
    currentFieldName: String,
    order: [String, null],
});
</script>
