<template>
    <table class="table table-hover">
        <thead>
        <tr>
            <th @click="() => setFilter('title')">
                Підприємство
                <header-order-icon fieldName="title" :currentFieldName="filter.field" :order="filter.order"/>
            </th>
            <th @click="() => setFilter('city')">
                Місто
                <header-order-icon fieldName="city" :currentFieldName="filter.field" :order="filter.order"/>
            </th>
            <th @click="() => setFilter('quantity')">
                Кількість
                <header-order-icon fieldName="quantity" :currentFieldName="filter.field" :order="filter.order"/>
            </th>
            <th @click="() => setFilter('price')" v-if="props.showPrice">
                Ціна
                <header-order-icon fieldName="price" :currentFieldName="filter.field" :order="filter.order"/>
            </th>
            <th @click="() => setFilter('validityDate')">
                Актуальність
                <header-order-icon fieldName="validityDate" :currentFieldName="filter.field" :order="filter.order"/>
            </th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="part in parts"
            :class="{'row-muted': moreThanTwoMonth(part.supplier.validityDate), 'row-primary': !moreThanTwoMonth(part.supplier.validityDate) && part.costsLessThanImporter}"
            :key="part.uuid"
        >
            <td>{{ part.supplier.title }}</td>
            <td>{{ part.supplier.city }}</td>
            <td>{{ part.quantity }}</td>
            <td v-if="props.showPrice">{{ uah(part.price) }}</td>
            <td>{{ humanizedDiff(part.supplier.validityDate) }}</td>
            <td>
                <div class="btn btn-sm btn-outline-primary" @click="() => props.onRowContactClick(part.supplier)">
                    <i class="bi bi-person-lines-fill"></i>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script setup>
import {uah, humanizedDiff, moreThanTwoMonth} from "@/helpers";
import {computed, reactive} from "vue";
import HeaderOrderIcon from "@/components/PartsTable/HeaderOrderIcon";
import {event} from "@/services/event";

const props = defineProps({
    parts: Array,
    onRowContactClick: Function,
    showPrice: Boolean,
});

const filter = reactive({
    field: 'validityDate',
    order: 'asc',
})

const cmpMap = {
    quantity_asc: (a, b) => a.quantity - b.quantity,
    quantity_desc: (a, b) => b.quantity - a.quantity,

    price_asc: (a, b) => a.price - b.price,
    price_desc: (a, b) => b.price - a.price,

    // Дата сортується у зворотньому порядку
    validityDate_asc: (a, b) => new Date(b.supplier.validityDate) - new Date(a.supplier.validityDate),
    validityDate_desc: (a, b) => new Date(a.supplier.validityDate) - new Date(b.supplier.validityDate),

    city_asc: (a, b) => a.supplier.city.localeCompare(b.supplier.city),
    city_desc: (a, b) => b.supplier.city.localeCompare(a.supplier.city),

    title_asc: (a, b) => a.supplier.title.localeCompare(b.supplier.title),
    title_desc: (a, b) => b.supplier.title.localeCompare(a.supplier.title),
};

const parts = computed(() => {
    /**
     * @type {Array<WarehousePart>}
     */
    const parts = props.parts;
    const fn = filter.field + '_' + filter.order;

    return parts.slice().sort(cmpMap[fn]);
})

const setFilter = (field) => {
    if (filter.field !== field) {
        filter.order = 'asc'
    } else {
        filter.order = {
            'asc': 'desc',
            'desc': null,
            null: 'asc',
        }[filter.order];
    }

    filter.field = field;

    event('onFilterClick', filter);
}

</script>

<style scoped>
tr.row-muted > td {
    --bs-text-opacity: 1;
    color: var(--bs-tertiary-color) !important;
}
tr.row-primary > td {
    --bs-text-opacity: 1;
    color: var(--bs-primary) !important;
}
</style>
